.menu-desktop {

    &__container {
        display: flex;
        align-items: center;
        width: 100%;
        margin-inline: 10%;
    }

    &__container-logo {
        display: flex;
        align-items: center;
    }


    &__logo {
        width: 300px !important;
    }


    &__nav {
        display: flex;
        align-items: center;
        width: 90%;
        margin-left: 5%;
        color: $white;
        gap: 1rem;
    }

    &__user {
        display: flex;
        gap: 1rem;
        align-items: flex-start;
    }
}

// .menu-desktop__containe-logo__log {
//     width: 85.2px !important;
//     height: 85.2px !important;
// }

.menu-desktop-barBottom {
    height: 4px;
    margin-top: 4px;
    background: linear-gradient(to right, #00A0A0, #009FCB);
}

.ant-menu-title-content {
    font-size: 17px;
}

@include for-desktop-mac-up {
    .menu-desktop {
        &__container {
            display: flex;
            align-items: center;
            margin: 5%;

            a {
                img {
                    width: 200px;
                }
            }
        }

        &__nav {
            display: flex;
            align-items: center;
            width: 100%;
            margin-left: 5%;
            color: $white;
            gap: 1rem;
        }

        &__user {
            display: flex;
            gap: 1rem;
            align-items: flex-start;
        }
    }

    .ant-menu-title-content {
        font-size: 15px;
    }

    .ant-progress-inner {
        width: 5rem !important;
        height: 5rem !important;
    }


}